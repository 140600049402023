@import "src/_app/styles/mixins.scss";
.header {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  column-gap: 8px;
  padding: 18px 16px;
  color: var(--primary);
  position: relative;
  height: 56px;

  @include respond(tablet) {
    height: 80px;
  }

  @include respond(tabletS) {
    height: 56px;
  }

  span {
    @include transition;
    color: var(--primary);
  }

  &__btn {
    cursor: pointer;
    color: var(--primary);

    &:active,
    &:hover {
      span {
        color: var(--secondary);
      }
    }

    &:disabled {
      span {
        color: var(--text_disable);
      }
      pointer-events: none;
    }
  }

  .count {
    display: flex;
    flex-flow: row nowrap;
    column-gap: 8px;
  }

  &__actions {
    position: absolute;
    inset-inline-end: 16px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    column-gap: 16px;
    align-items: center;
    width: fit-content;
  }

  &__readAll_btn {
    height: 36px;
    color: var(--primary);
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    column-gap: 8px;
    cursor: pointer;

    @include transition;

    &:hover,
    &:active {
      color: var(--primary);

      span {
        color: var(--secondary_header);
      }
    }

    &:disabled {
      color: var(--text_disable);

      span {
        color: var(--text_disable);
      }
      pointer-events: none;
    }

    .readAll_btn__text {
      @include respond(tablet) {
        display: none;
      }
    }
  }

  &__close_btn {
    border: none;
    background: none;
    color: var(--primary);
    width: 24px;
    height: 24px;
    cursor: pointer;

    @include transition;

    &:hover,
    &:active {
      color: var(--secondary);
    }
  }
}
