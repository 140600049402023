@import "src/_app/styles/mixins.scss";
.header {
  background: var(--black);
  color: var(--white);

  &_container {
    margin: 0 auto;
    max-width: 100dvw;
    min-width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    column-gap: 8px;
    padding: 26px 32px;
    align-items: center;
    height: 92px;

    @include respond(laptop) {
      padding: 20px 32px;
      height: 80px;
    }

    @include respond(tablet) {
      padding: 20px 24px;
    }

    @include respond(tabletS) {
      height: 72px;
    }

    @include respond(mobileL) {
      padding: 18px 16px;
    }
  }

  &__main_nav {
    display: flex;
    column-gap: 16px;
    align-items: center;
  }

  .divider {
    width: 1px;
    height: 24px;
    background: #2d2d2d;
    border-radius: 1px;
    display: block;

    @include respond(laptopC) {
      display: none;
    }

    &.tablet {
      display: none;

      @include respond(laptopC) {
        display: block;
      }

      @include respond(tabletS) {
        display: none;
      }
    }

    &.long_lang {
      @include respond(laptopL) {
        display: block;

        @include respond(tabletS) {
          display: none;
        }
      }
    }
  }
}
