// Media Queries Mixins
@mixin landscape {
  @media only screen and (height <= 576px) and (orientation: landscape) {
    @content;
  }
}

@mixin respond($breakpoint) {
  @if $breakpoint == laptopXL {
    @media only screen and (width <= 2560px) {
      @content;
    }
  }

  @if $breakpoint == desktop {
    @media only screen and (width <= 1920px) {
      @content;
    }
  }

  @if $breakpoint == laptopL {
    @media only screen and (width <= 1440px) {
      @content;
    }
  }

  @if $breakpoint == laptopC {
    @media only screen and (width <= 1150px) {
      @content;
    }
  }

  @if $breakpoint == laptop {
    @media only screen and (width <= 1024px) {
      @content;
    }
  }

  @if $breakpoint == tabletL {
    @media only screen and (width <= 920px) {
      @content;
    }
  }

  @if $breakpoint == tablet {
    @media only screen and (width <= 768px) {
      @content;
    }
  }

  @if $breakpoint == tabletS {
    @media only screen and (width <= 576px) {
      @content;
    }
  }

  @if $breakpoint == mobileL {
    @media only screen and (width <= 425px) {
      @content;
    }
  }

  @if $breakpoint == mobile {
    @media only screen and (width <= 375px) {
      @content;
    }
  }
}

// Smooth Transition
@mixin transition {
  transition:
    all 0.3s ease,
    outline-offset 0s,
    outline-color 0s,
    outline 0s;
}

@mixin limitTextLines($linesNum) {
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: $linesNum;
  line-clamp: $linesNum;
  -webkit-box-orient: vertical;
}

@mixin rtl {
  html[dir='rtl'] & {
    @content;
  }
}

@mixin ltr {
  html[dir='ltr'] & {
    @content;
  }
}

@mixin tButton {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}

@mixin status {
  padding: 4px 8px;
  border-radius: 20px;
  display: inline-block;

  &.closed {
    background: var(--aurora_neutral_30);
    color: var(--aurora_neutral_60);
  }

  &.open {
    background: var(--aurora_special_green_bg);
    color: var(--aurora_text_inverted);
  }
}

@mixin hideScrollbar {
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

@mixin customScrollbar {
  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-left: 1px solid var(--aurora_select_border);
    border-right: 1px solid var(--aurora_select_border);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--aurora_text_secondary);
    border-radius: 6px;
    border: 3px solid transparent;
    background-clip: padding-box;
  }

  * {
    &::-webkit-scrollbar {
      width: 12px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
      border-left: 1px solid var(--aurora_select_border);
      border-right: 1px solid var(--aurora_select_border);
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--aurora_text_secondary);
      border-radius: 6px;
      width: 6px;
      border: 3.5px solid transparent;
      background-clip: padding-box;
    }
  }
}
