@import "src/_app/styles/mixins.scss";
a.logo {
  display: flex;
  align-items: center;
  gap: 12px;

  @include respond(laptop) {
    gap: 9px;
  }

  @include respond(mobileL) {
    gap: 8px;
  }

  svg {
    height: 48px;
    width: 234px;

    @include respond(laptop) {
      height: 36px;
      width: 176px;
    }

    @include respond(mobileL) {
      height: 34px;
      width: 166px;
    }
  }
}
