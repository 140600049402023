@import "src/_app/styles/mixins.scss";
.emptyList {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 155px;
  row-gap: 32px;
  color: var(--secondary);
}
