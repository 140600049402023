@import "src/_app/styles/mixins.scss";
.wallets_links {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 8px;
  margin-top: 8px;
  border-top: 1px solid var(--border_header);

  &.burger {
    border: none;
    padding: 0;
    margin: 0;
  }
}
