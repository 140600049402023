@import "src/_app/styles/mixins.scss";
.avatar_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  background: none;

  &:focus-visible {
    outline-color: var(--white) !important;
  }

  @include respond(tabletS) {
    width: 32px;
    height: 32px;
  }
}
