@import "src/_app/styles/mixins.scss";
.header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  padding: 28px 32px;

  @include respond(tablet) {
    padding: 28px 24px;
  }

  @include respond(tabletS) {
    padding: 18px 16px;
    height: 60px;
  }

  &.title {
    justify-content: space-between;
    width: 100%;
  }

  &.reversed {
    flex-direction: row-reverse;
  }

  &__btn {
    width: 24px;
    height: 24px;
    color: var(--primary);

    @include transition;
    cursor: pointer;

    &:hover,
    &:active {
      color: var(--secondary);
    }
  }
}
