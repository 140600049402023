@import "src/_app/styles/mixins.scss";
.wrapper {
  position: fixed;
  left: 24px;
  bottom: 24px;
  border: 1px solid var(--border_1);
  max-width: 404px;
  overflow: hidden;

  padding: 24px;
  border-radius: 20px;

  background: var(--bg_cookieModal);

  color: var(--primary);

  z-index: 99999999;

  @include sElevation_1_bordered;

  &_dark {
    border: none;
    box-shadow: -10px 0 80px 0 rgb(0 0 0 / 10%);
  }

  @include respond(tabletS) {
    width: 100%;
    max-width: 343px;

    left: 50%;
    transform: translate(-50%, -5%);

    bottom: 24px;
    border-radius: 20px;
    padding: 16px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 8px;

    @include respond(tabletS) {
      margin-bottom: 16px;
    }

    img {
      cursor: pointer;
      color: var(--secondary);
    }

    h2 {
      @include tSubtitle_Semibold;
      margin: 0;
    }

    .header__title {
      display: flex;
      align-items: flex-start;
    }

    .toggleBtn {
      display: none;

      @include respond(tabletS) {
        display: block;
      }
    }

    .close__btn {
      cursor: pointer;
    }
  }

  .button_wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;

    button {
      font-family: Golos, sans-serif;
    }
  }

  .link {
    &:hover {
      color: var(--text_1);
    }
  }

  .text__wrapper {
    &_desktop {
      margin-bottom: 16px;

      @include respond(tabletS) {
        display: none;
      }
    }

    &_mobile {
      display: none;

      @include respond(tabletS) {
        display: block;
      }
    }

    .text {
      @include tCaption;
      color: var(--primary);
      overflow: hidden;
      margin: 0;

      @include respond(tablet) {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}
