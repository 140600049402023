@import "src/_app/styles/mixins.scss";
.container {
  background: var(--mainPrimary);
  color: #c5c5c5;
  border-top: 1px solid #242424;
  width: 100%;
  padding: 0 80px;
  overflow: hidden;

  @include respond(laptop) {
    padding: 0 32px;
  }

  @include respond(tabletS) {
    padding: 0 16px;
  }

  .footer {
    width: 100%;
    max-width: 1280px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    .info {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 68px 0;
      direction: ltr;

      @include respond(laptop) {
        flex-direction: column;
        padding: 32px 0;
      }

      .logo {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        color: white;
        margin-inline-end: 16px;

        @include respond(laptop) {
          display: block;
          width: 100%;
          margin-bottom: 68px;
        }

        @include respond(tablet) {
          flex-direction: column;
          align-items: flex-start;
          margin-bottom: 32px;
        }

        .main {
          display: flex;
          flex-direction: column;
          gap: 24px;

          .download {
            display: flex;
            align-items: center;
            gap: 16px;
          }

          @include respond(laptop) {
            display: grid;
            grid-template-columns: max-content 1fr max-content;
            grid-template-rows: max-content max-content;
            max-width: 100%;
            align-items: center;
          }

          @media only screen and (width <= 950px) {
            grid-template-columns: auto;
          }

          @include respond(tablet) {
            grid-template-columns: 1fr max-content;
            row-gap: 16px;
          }
        }

        .logoWrapper {
          @include respond(laptop) {
            grid-column: 1 / 3;
          }
        }

        .trastpilot {
          @include respond(laptop) {
            grid-column: 3;
            text-align: right;
          }

          @include respond(tablet) {
            grid-column: 2;
            grid-row: 3;
          }
        }

        .appstore {
          @include respond(laptop) {
            grid-row: 2;
          }

          @include respond(tablet) {
            grid-column: 1;
            grid-row: 3;
          }
        }

        .certikWrapper {
          width: 150px;

          @include respond(laptop) {
            grid-row: 3;
            grid-column: 1;
          }

          @include respond(tablet) {
            grid-column: 1;
            grid-row: 4;
          }
        }

        p {
          margin: 0;
          color: #c5c5c5;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.12px;
          max-width: 368px;

          @include respond(laptop) {
            grid-column: 3;
            text-align: right;
          }

          @include respond(tablet) {
            grid-row: 2;
            grid-column: 1 / 3;
            text-align: left;
          }
        }
      }

      .links {
        display: grid;
        grid-template-columns: 90px 120px 160px 75px;
        grid-column-gap: 90px;
        max-width: 700px;
        width: 100%;
        grid-template-areas: 'area1 area2 area3 area4';

        @include respond(laptopC) {
          grid-column-gap: 60px;
          justify-content: flex-end;
        }

        @include respond(laptop) {
          max-width: 100%;
          grid-template-columns: repeat(4, 1fr);
        }

        @include respond(tablet) {
          grid: repeat(2, 1fr) / repeat(2, 1fr);
          gap: 32px 0;
          grid-template-areas:
            'area1 area3'
            'area2 area4';
        }

        .area-1 {
          grid-area: area1;
        }

        .area-2 {
          grid-area: area2;
        }

        .area-3 {
          grid-area: area3;
        }

        .area-4 {
          grid-area: area4;
        }

        div {
          display: flex;
          flex-direction: column;
          gap: 16px;

          h3 {
            color: #fff;
            margin: 0;
            font-weight: 600;
            font-size: 18px;
            line-height: 24px;
          }

          a {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.12px;
            color: #c5c5c5;
          }
        }
      }
    }

    .social {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 20px;
      padding: 16px 0;

      @include respond(tabletL) {
        flex-wrap: wrap;
      }

      @include respond(tabletS) {
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
      }

      .links {
        display: flex;
        gap: 16px;
        flex-wrap: wrap;

        @include respond(tabletS) {
          gap: 8px 20px;
        }

        a {
          width: 36px;
          height: 36px;
          display: flex;
          align-items: center;
          float: left;
          align-self: center;
        }

        svg {
          margin: auto;
        }

        svg path {
          fill: var(--mainGray);
        }

        .small_icon {
          transform: translateY(2px);

          svg path {
            fill: var(--footerGray);
          }
        }
      }

      .rights {
        display: flex;
        justify-content: space-between;
        max-width: 480px;
        gap: 20px;

        @include respond(tablet) {
          gap: 16px;
          flex-direction: row-reverse;
        }

        @include respond(mobileL) {
          // max-width: 265px;
        }

        p {
          margin: 0;
        }
      }
    }

    .juridical {
      display: flex;
      flex-direction: column;
      width: 100%;
      row-gap: 10px;
      padding: 24px 0;

      p {
        margin: 0;
      }
    }

    .rights,
    .juridical {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.08px;
      color: #c5c5c5;
    }
  }
}
