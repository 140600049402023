@import "src/_app/styles/mixins.scss";
.item {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  column-gap: 16px;
  color: var(--primary);
  cursor: pointer;

  @include transition;
  width: 100%;
  padding: 12px 32px;

  @include respond(tablet) {
    padding: 12px 24px;
  }

  @include respond(tabletS) {
    padding: 12px 16px;
  }

  &.with_child {
    padding-inline-end: calc(24px + 32px);

    @include respond(tablet) {
      padding-inline-end: calc(24px + 24px);
    }

    @include respond(tabletS) {
      padding-inline-end: calc(24px + 16px);
    }
  }

  &:hover,
  &.hovered,
  &:active {
    background: var(--bgHeaderItemHover);

    .item__icon {
      background: var(--select_item_hover);
    }
  }

  &:focus-visible {
    outline-offset: -1px;
  }

  &__content {
    display: flex;
    flex-flow: column nowrap;
    text-align: start;
    justify-content: center;

    span {
      color: var(--primary);
    }
  }
}
