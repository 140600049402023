@import "src/_app/styles/mixins.scss";
.user {
  display: flex;
  flex-flow: row nowrap;
  column-gap: 16px;
  padding: 8px 16px;
  color: var(--primary);
  background: var(--select_default);

  @include transition;

  &__info {
    display: flex;
    flex-direction: column;
  }

  &__uid {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    column-gap: 4px;
    color: var(--secondary_header);

    button {
      color: var(--secondary_header);
      cursor: pointer;

      svg {
        transform: translateY(1px);
      }

      &:focus-visible {
        outline-offset: 2px;
        border-radius: 2px;
      }
    }
  }
}

.kyc_skeleton {
  width: 160px;
  height: 20px;
}
