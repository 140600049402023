@import "src/_app/styles/mixins.scss";
.control_list {
  display: flex;
  flex-direction: row;
  column-gap: 16px;
  align-items: center;
}

.user_center {
  @include respond(tabletS) {
    order: 1;
  }
}

.notifications {
  @include respond(tabletS) {
    order: 0;
  }
}

.divider {
  width: 1px;
  height: 24px;
  background: #2d2d2d;
  border-radius: 1px;
  display: block;

  @include respond(tabletS) {
    display: none;
  }
}
