@import "src/_app/styles/mixins.scss";
.qr_menu {
  color: var(--primary);
  padding: 8px 16px;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  align-items: center;
  background: var(--select_default);

  &__qr {
    position: relative;
    padding: 24px;
    border: 1px solid var(--border_header);
    border-radius: 16px;

    .qr__logo {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background: var(--bg_modal_header);
      padding: 8px;
      border-radius: 10px;

      svg {
        width: 32px;
        height: 32px;
      }
    }
  }

  &__btn {
    width: 100%;
  }

  span {
    text-align: center;
    color: var(--secondary);
  }
}
