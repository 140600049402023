@import "src/_app/styles/mixins.scss";
.un_auth_items__wrapper {
  display: flex;
  flex-flow: row nowrap;
  column-gap: 32px;
  align-items: center;

  @include respond(laptop) {
    column-gap: 16px;
  }

  .divider {
    display: none;

    @include respond(laptop) {
      display: block;
      width: 1px;
      height: 24px;
      background: #2d2d2d;

      @include respond(tabletS) {
        display: none;
      }
    }
  }

  .get_started_button {
    background: var(--white) !important;
    color: #0a0a0a !important;

    &:focus-visible {
      span {
        background: #a1a1a1;
      }
    }

    span {
      display: flex;

      @include respond(tabletS) {
        display: none;
      }

      &:hover {
        background: #a1a1a1;
      }

      &:active {
        background: #7a7a7a;
      }
    }
  }
}
