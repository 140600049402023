@import "src/_app/styles/mixins.scss";
.supportButton {
  height: 64px;
  width: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--bg_buttonUp);
  position: fixed;
  bottom: 24px;
  right: 24px;
  border: none;
  box-shadow: rgb(23 73 77 / 15%) 0 20px 30px;
  z-index: 98;
  border-radius: 50%;
  padding: 0;
  user-select: none;
  cursor: pointer;

  @media only screen and (hover: none) and (pointer: coarse) {
    bottom: 78px;
  }
}
