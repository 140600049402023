@import "src/_app/styles/mixins.scss";
.logo__wrapper {
  fill: white;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;

  &:focus-visible {
    outline-color: var(--white);
  }

  @include respond(tabletS) {
    gap: 8px;
  }
}

.logo__icon {
  width: 32px;
  height: 36px;

  @include respond(tabletS) {
    width: 21px;
    height: 24px;
  }
}

.logo__text {
  width: 131px;
  height: 22px;

  @include respond(tabletS) {
    width: 87px;
    height: 15px;
  }
}
