@import "src/_app/styles/mixins.scss";
.kyc_wrapper {
  width: fit-content;
  margin-top: 8px;
}

.kyc_badge {
  display: flex;
  flex-flow: row nowrap;
  column-gap: 8px;
  align-items: center;
  color: var(--primary);

  .kyc_badge__icon {
    height: 20px;
  }

  &.in_progress {
    svg:first-child path {
      color: var(--special_orange);
    }
  }

  &.not_passed {
    svg:first-child path {
      color: var(--primary);
    }
  }

  &.approved {
    color: var(--special_green);
  }

  .badge__arrow {
    @include rtl {
      transform: rotate(180deg);
    }
  }
}
