@import "src/_app/styles/mixins.scss";
.support__item {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  column-gap: 16px;
  padding: 8px 16px;
  align-items: center;
  width: 100%;

  background: var(--select_default);

  @include transition;

  &:focus-visible {
    outline-offset: -1px;
  }

  &:hover,
  &:active {
    background: var(--bgHeaderItemHover);

    .item__icon {
      background: var(--select_item_hover);
    }
  }

  .item__container {
    display: flex;
    flex-flow: row nowrap;
    column-gap: 16px;
    align-items: center;

    .item__content {
      display: flex;
      flex-direction: column;

      .content__title {
        display: flex;
        flex-flow: row nowrap;
        column-gap: 8px;
        align-items: center;
      }

      .content__description {
        color: var(--secondary_header);
      }
    }
  }
}

.counter {
  flex-shrink: 0;
}
