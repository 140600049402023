@import "src/_app/styles/mixins.scss";
.nav__item {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  column-gap: 16px;
  padding: 8px 16px;
  background: var(--select_default);
  color: var(--primary);

  @include transition;
  cursor: pointer;
  text-align: start;
  width: 100%;

  &.with_children {
    padding-inline-end: 15%;
  }

  &:hover,
  &:active {
    background: var(--bgHeaderItemHover);

    .item__icon {
      background: var(--select_item_hover);
    }
  }

  &:focus-visible {
    &::after {
      inset: 2px 6px !important;
    }
  }

  .content {
    display: flex;
    flex-direction: column;

    p {
      color: var(--primary);
    }

    span {
      color: var(--primary);
    }

    &__description {
      color: var(--secondary_header) !important;
    }
  }
}

.item__icon {
  color: var(--primary);
}
