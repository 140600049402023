@import "src/_app/styles/mixins.scss";
.burger_btn_wrapper {
  display: none;

  @include respond(laptopL) {
    display: block;
  }

  &.long_lang {
    @include respond(laptopL) {
      display: block;
    }
  }
}

.burger_btn {
  &:focus-visible {
    outline-color: var(--white) !important;
  }
}

.get_started_btn {
  width: 100%;
  display: none;
  padding: 12px 16px;

  @include respond(tabletS) {
    display: block;
  }

  a,
  button {
    width: 100%;
  }
}
