@import "src/_app/styles/mixins.scss";
.wrapper {
  width: 100%;
  text-align: start;

  &:focus-visible {
    outline-offset: -1px;
  }
}

.item {
  display: flex;
  flex-direction: row;
  column-gap: 16px;
  align-items: center;
  position: relative;
  color: var(--primary);
  padding: 8px 16px;
  background: var(--select_default);

  @include transition;
  cursor: pointer;

  &:hover,
  &:active {
    background: var(--bgHeaderItemHover);

    .item__icon {
      background: var(--select_item_hover);
    }
  }

  &__content {
    display: flex;
    flex-direction: column;

    span {
      color: var(--primary);
    }

    &__title {
      font-weight: 500 !important;
    }

    &__subTitle {
      color: var(--secondary_header) !important;
    }
  }

  .right_el {
    position: absolute;
    inset-inline-end: 16px;
    top: 50%;
    transform: translateY(-50%);

    &.with_sub {
      inset-inline-end: calc(16px + 24px + 16px);
    }
  }
}
