@import "src/_app/styles/mixins.scss";
.currency_item {
  width: 100%;
  position: relative;
  flex-wrap: nowrap;
  color: var(--primary);
  cursor: pointer;

  @include transition;
  display: flex;
  align-items: center;

  &_default {
    padding: 12px 16px;
    column-gap: 16px;
    background: var(--select_default);

    &:hover,
    &:active {
      background: var(--bgHeaderItemHover);
    }

    .currency_item__symbol {
      width: 24px;
      height: 24px;

      background: var(--neutral_80);
    }
  }

  &_small {
    padding: 0;
    column-gap: 4px;

    .currency_item__symbol {
      width: 16px;
      height: 16px;

      background: var(--neutral_80);
    }
  }

  &__symbol {
    overflow: hidden;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--text_inverted);
  }
}
