@import "src/_app/styles/mixins.scss";
.avatar_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  color: var(--primary);

  span {
    position: absolute;
    top: calc(50%);
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
