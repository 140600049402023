@import "src/_app/styles/mixins.scss";
.buttonUp_container {
  position: fixed;
  background: var(--bg_buttonUp);
  border-radius: 76px;
  border: none;
  width: 64px;
  height: 64px;
  right: 108px;
  bottom: 24px;
  z-index: 100;
  pointer-events: none;
  opacity: 0;
  transition: 0.3s;
  padding: 0;

  @media only screen and (hover: none) and (pointer: coarse) {
    bottom: 78px;
  }
}

.buttonUp_visible {
  pointer-events: all;
  opacity: 1;
  cursor: pointer;
}
