@import "src/_app/styles/mixins.scss";
.nav_list {
  display: flex;
  flex-direction: row;
  column-gap: 16px;
  align-items: center;
  padding: 0;
  padding-inline-start: 16px;
  border-inline-start: 1px solid #2d2d2d;
  list-style: none;

  li {
    list-style: none;
  }

  @include respond(laptopL) {
    display: none;
  }

  &.long_lang {
    @include respond(laptopL) {
      display: none;
    }
  }

  .list__item,
  li,
  & > p {
    @include transition;
    color: var(--white);

    @include transition;

    &:hover,
    &:active {
      color: #b1b1b1;
    }
  }

  .other_links {
    display: flex;
    align-items: center;
    column-gap: 2px;

    p {
      color: var(--white);

      @include transition;

      &:hover,
      &:active {
        color: #b1b1b1;
      }
    }

    &:focus-visible {
      outline-color: var(--white);

      p {
        color: #b1b1b1;
      }
    }
  }

  .tag {
    background: #3ac270;
    border-radius: 10px;
    padding: 1px 6px;
    color: var(--black);
    width: fit-content;
    height: fit-content;
  }
}
