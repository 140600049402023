@import "src/_app/styles/mixins.scss";
.tgButton {
  width: 100%;
  height: 100%;

  svg {
    path {
      &:first-of-type {
        fill: var(--bg_buttonUp);
      }

      &:last-of-type {
        fill: var(--primary);
      }
    }
  }
}
